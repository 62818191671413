import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("Hello Reaction!")
  }

  openModal() {
    console.log("openModal");
    document.getElementById("reaction_menu").style.display = 'block';
  }

  closeModal() {
    console.log("closeModal");
    let m = document.getElementById("reaction_menu");
    m.style.display = 'none';
    m.removeAttribute("src")
  }

  toggle(e) {
     console.log("toggle");
  };
}
