// Import and register all your controllers from the importmap under controllers/*

import { application } from "./application"

import MenuController from "./menu_controller"
application.register("menu", MenuController)

import PinningsController from "./pinnings_controller"
application.register("pinnings", PinningsController)

import ReactionController from "./reaction_controller"
application.register("reaction", ReactionController)

