import { Controller } from "@hotwired/stimulus"
require("konva")
import HoldsPinner from 'climbing-holds-pinner.js';

export default class extends Controller {
  static values = {
    width: Number,
    height: Number,
    numerable: Boolean,
    holds: Array,
    savepath: String,
    redirect: String
  }

  connect() {
    console.log("Hello Pinnings!")
    this.holds_pinner = new HoldsPinner({target: 'canvasDiv', 
                                          width: this.widthValue, 
                                          height: this.heightValue, 
                                          editable: true, 
                                          numerable: this.numerableValue});
    const startingHolds = this.holdsValue;
    console.log('startingHolds: ')
    console.log(startingHolds)

    this.holds_pinner.setup(startingHolds);
  }

  clear_selected() {
    document.querySelector('#start_hold_btn').classList.remove("pinning-selected-button");
    document.querySelector('#hold_btn').classList.remove("pinning-selected-button");
    document.querySelector('#top_hold_btn').classList.remove("pinning-selected-button");
  }

  start_hold_selected() {
    this.clear_selected()
    document.querySelector('#start_hold_btn').classList.add("pinning-selected-button");
    this.holds_pinner.change_hold_type('start')
  }

  simple_hold_selected() {
    this.clear_selected()
    document.querySelector('#hold_btn').classList.add("pinning-selected-button");
    this.holds_pinner.change_hold_type('hold')
  }

  top_hold_selected() {
    this.clear_selected()
    document.querySelector('#top_hold_btn').classList.add("pinning-selected-button");
    this.holds_pinner.change_hold_type('top')
  }

  increase_hold_size() {
    this.holds_pinner.increase_size();
  }

  decrease_hold_size() {
    this.holds_pinner.decrease_size();
  }

  undo() {
    this.holds_pinner.undo();
  }

  exit() {
    window.location.href = this.redirectValue;
  }

  save_holds() {
    let redirect_url = this.redirectValue;
    var result = {};
    result['holds'] = this.holds_pinner.get_holds();
    result['image'] = this.holds_pinner.export_image();
    console.log(JSON.stringify(result));
    let myRequest = new Request(this.savepathValue, 
      { 
        method: 'POST', 
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(result)
      }
    );
    fetch(myRequest).then(function(response) {
      console.log(response);
      if (response.statusText === 'OK') {
        window.location.href = redirect_url;
      };
    });
  }
}
