import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  toggle(e) {
    var modalMenu = document.querySelector("#modal-menu");
    var modalOpener = document.querySelector("#modal-opener");

    console.log('Menu toggle');
    modalMenu.classList.toggle("active");
    modalOpener.classList.toggle("active");
  };
}
